import "../pages/homepage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Typewriter } from "react-simple-typewriter";
import Tooltip from "@mui/material/Tooltip";
import SaasLogo from "../assets/SaaS-Alerts-Logo.png";
import { useEffect } from "react";
export const HomePageHero = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
    window.scroll(0, 0);
  }, []);

  return (
    <div className="hero-container">
      <video
        src="https://cdn.pixabay.com/video/2023/10/10/184489-873483996_small.mp4"
        loop
        muted
        autoPlay
        className="video-player"
        playsInline
        preload="metadata"
      >
        Your browser does not support the video tag.
      </video>
      <div className="dark-overlay"></div>
      <div className="hero-txt-container-carousel">
        <h1>
          We{" "}
          <span style={{ color: "red", fontWeight: "bold" }}>
            <Typewriter
              words={["Empower", "Enhance", "Ease", "Optimize"]}
              loop={5}
              cursor
              cursorStyle="_"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </span>
          Technology For You
        </h1>
        <a href="https://consultlm.syncromsp.com/bookings" className="book-btn">
          Book Now
        </a>
      </div>
    </div>
  );
};

export const HomeCont = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);
  return (
    <div className="home-container">
      <h1 className="home-title">Meet The Stack</h1>
      <div className="section-container">
      <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1056.36 228.17"
            >
              <defs>
                <style></style>
              </defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    class="ninja-logo-1"
                    d="M971.12,79.85q-8.34,7.32-10.19,20h61.38q-1.86-12.45-10.19-19.9a29.51,29.51,0,0,0-20.39-7.46Q979.46,72.51,971.12,79.85Zm76.33,78Q1029.87,179,996.12,179c-14,0-26.48-2.91-37.27-8.68a61.1,61.1,0,0,1-25-24.08Q925,130.92,925,111.36a70,70,0,0,1,8.7-34.79,62.35,62.35,0,0,1,23.82-24.18,70.45,70.45,0,0,1,67.07-.39,59.27,59.27,0,0,1,23.3,23.8c5.63,10.39,8.44,22.37,8.44,36.05,0,.5-.26,3.9-.67,10.22H961.41a30.87,30.87,0,0,0,12,19.35c6.31,4.72,14.18,7,23.61,7a45.61,45.61,0,0,0,17.26-3,30.17,30.17,0,0,0,3.54-1.7Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M113.16,60.12Q127.4,74.78,127.4,103.6v75.23H91.27V109.46q0-15.63-6.49-23.33T66,78.44q-13.66,0-21.77,8.91t-8.11,26.5v65H0V58.41L34.52,41.92V62.81A47.68,47.68,0,0,1,52.35,50a59.21,59.21,0,0,1,23.4-4.53Q98.91,45.46,113.16,60.12Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M154.29,58.43V178.84h36.12V41.94Z"
                  ></path>
                  <polygon
                    class="ninja-letters"
                    points="190.41 0.03 190.41 30.93 154.29 47.41 154.29 16.52 190.41 0.03"
                  ></polygon>
                  <polygon
                    class="ninja-letters"
                    points="371.21 16.55 371.21 47.45 407.32 30.9 407.32 0 371.21 16.55"
                  ></polygon>
                  <path
                    class="ninja-letters"
                    d="M331.36,60.12Q345.6,74.78,345.6,103.6v75.23H309.47V109.46q0-15.63-6.49-23.33t-18.76-7.69q-13.67,0-21.77,8.91t-8.11,26.5v65H218.2V58.41l34.52-16.49V62.81A47.68,47.68,0,0,1,270.55,50,59.21,59.21,0,0,1,294,45.46Q317.11,45.46,331.36,60.12Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M407.33,42.26V178.58q0,23-12.39,36.29t-34.64,13.3q-19,0-28.94-7.32l9.71-27.6q6.26,4.63,15.29,4.62c4.64,0,8.25-1.57,10.89-4.75s3.94-7.94,3.94-14.29v-120Z"
                  ></path>
                  <path
                    class="ninja-letters"
                    d="M561.36,58.41V178.83H526.84V163.68q-13.44,17.12-38.91,17.1a62,62,0,0,1-31.85-8.3,58,58,0,0,1-22.35-23.7q-8.12-15.37-8.11-35.66t8.11-35.66a58,58,0,0,1,22.35-23.69,62,62,0,0,1,31.85-8.31q23.85,0,37.29,15.88V41.92Zm-44.48,81.21q9-9.88,9-26.5t-9-26.5a29.42,29.42,0,0,0-22.7-9.89q-13.89,0-22.93,9.89t-9,26.5q0,16.62,9,26.5t22.93,9.89A29.42,29.42,0,0,0,516.88,139.62Z"
                  ></path>
                  <path
                    class="ninja-logo-1"
                    d="M624.48,170.28a82.82,82.82,0,0,1-31.74-31.64q-11.46-20.14-11.46-45.3T592.74,48A82.86,82.86,0,0,1,624.48,16.4Q644.75,4.93,670,4.92T715.39,16.4A83.83,83.83,0,0,1,747.13,48q11.58,20.14,11.58,45.31t-11.58,45.3a83.79,83.79,0,0,1-31.74,31.64Q695.25,181.76,670,181.76T624.48,170.28Zm71.46-29.19a48.84,48.84,0,0,0,18.19-19.42,59.66,59.66,0,0,0,6.6-28.33A59.66,59.66,0,0,0,714.13,65a48.93,48.93,0,0,0-18.19-19.43,51.84,51.84,0,0,0-51.89,0A48.84,48.84,0,0,0,625.87,65a59.55,59.55,0,0,0-6.61,28.33,59.55,59.55,0,0,0,6.61,28.33,48.75,48.75,0,0,0,18.18,19.42,51.84,51.84,0,0,0,51.89,0Z"
                  ></path>
                  <path
                    class="ninja-logo-1"
                    d="M891.79,60.12Q906,74.78,906,103.6v75.23H869.89V109.46q0-15.63-6.48-23.33t-18.76-7.69q-13.67,0-21.77,8.91t-8.12,26.5v65H778.63V58.41l34.52-16.49V62.81A47.68,47.68,0,0,1,831,50a59.18,59.18,0,0,1,23.4-4.53Q877.52,45.46,891.79,60.12Z"
                  ></path>
                </g>
              </g>
            </svg>
            <h2 className="home-section-title">NinjaOne</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Streamlined IT Management:</strong> Manages IT
                  endpoints and workflows efficiently.
                </li>
                <li>
                  <strong>Comprehensive Endpoint Management:</strong> Provides
                  complete visibility and device optics, ensuring secure and
                  healthy devices.
                </li>
                <li>
                  <strong>Automated Patch Management:</strong> Enhances security
                  with automated patch identification, evaluation, and
                  deployment for compliance.
                </li>
                <li>
                  <strong>Robust Backup Solutions:</strong> Protects critical
                  business data with cloud-first endpoint backup, safeguarding
                  against data loss and ransomware.
                </li>
                <li>
                  <strong>End User:</strong> Offers remote access capabilities,
                  even for phones and tablets.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31"
              alt="logo"
            />
            <h2 className="home-section-title">Microsoft</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Cloud-Based Suite:</strong> Offers cloud-based access
                  to Microsoft Office applications like Word, Excel, PowerPoint,
                  and Outlook.
                </li>
                <li>
                  <strong>Collaboration Tools:</strong> Includes tools like
                  Microsoft Teams for real-time collaboration and communication.
                </li>
                <li>
                  <strong>Storage Solutions:</strong> Provides OneDrive for
                  Business for secure cloud storage and file sharing.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.bitdefender.com/adobe/dynamicmedia/deliver/dm-aid--ff62d212-3a6d-4993-841c-7458d12cdf2f/bitdefender-blue-logo-png.png?width=1920&preferwebp=true&quality=85"
              alt="logo"
            />
            <h2 className="home-section-title">Bitdefender</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Anti-Malware:</strong> Defends against viruses, worms,
                  Trojans, spyware, adware, and more.
                </li>
                <li>
                  <strong>Advanced Anti-Exploit:</strong> Detects and blocks
                  known and zero-day exploits targeting software applications.
                </li>
                <li>
                  <strong>Fileless Attack Defense:</strong> Stops sophisticated
                  threats like fileless and script-based attacks.
                </li>
                <li>
                  <strong>Anti-Phishing:</strong> Blocks phishing web pages to
                  prevent data breaches.
                </li>
                <li>
                  <strong>Web Threat Protection:</strong> Prevents malware
                  downloads and stops access to fraudulent web pages.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img src={SaasLogo} alt="logo" />
            <h2 className="home-section-title">Saas Alerts</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Real-Time Monitoring:</strong> Provides unified,
                  real-time monitoring of core SaaS business applications.
                </li>
                <li>
                  <strong>Data Protection:</strong> Protects against data theft
                  and actions by bad actors.
                </li>
                <li>
                  <strong>Cyber Insurance:</strong> Offers cyber insurance to
                  help manage financial risks associated with cyber threats.
                </li>
                <li>
                  <strong>Alerts and Remediation:</strong> Delivers real-time
                  alerts and remediation for cloud threats.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.bitdefender.com/adobe/dynamicmedia/deliver/dm-aid--ff62d212-3a6d-4993-841c-7458d12cdf2f/bitdefender-blue-logo-png.png?width=1920&preferwebp=true&quality=85"
              alt="logo"
            />
            <h2 className="home-section-title">Extended Security</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Managed Detection and Response (MDR):</strong>
                </li>
                <ul>
                  <li>
                    <strong>24/7 Monitoring:</strong> Continuous threat
                    monitoring and response by security experts.
                  </li>
                  <li>
                    <strong>Proactive Defense:</strong> Identifies and mitigates
                    threats before they impact your business.
                  </li>
                  <li>
                    <strong>Expert Support:</strong> Access to a dedicated team
                    of cybersecurity professionals.
                  </li>
                </ul>
              </ul>
            </p>
          </div>
        </section>
        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img src="https://www.autoelevate.com/wp-content/uploads/2018/10/AutoElevate-Logo-by-CyberFOX-060122b-e1654603999434.png" alt="logo" />
            <h2 className="home-section-title">Auto Elevate</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Enhanced Security:</strong> Reduces vulnerabilities
                  and protects against cyber threats by removing local admin
                  rights.
                </li>
                <li>
                  <strong>Seamless Productivity:</strong> Maintains robust
                  security without disrupting user workflows.
                </li>
                <li>
                  <strong>Remote Management:</strong> Manages privileges,
                  approves requests, and automates ticketing from anywhere.
                </li>
                <li>
                  <strong>Compliance Ready:</strong> Easily meets cybersecurity
                  best practices and insurance requirements.
                </li>
              </ul>
            </p>
          </div>
        </section>

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.passwordboss.com/wp-content/uploads/2022/06/Password-Boss-Logo.png"
              alt="logo"
            />
            <h2 className="home-section-title">Password Boss</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Enhanced Security:</strong> Protects against 81% of
                  security breaches caused by weak or stolen passwords.
                </li>
                <li>
                  <strong>Secure Sharing:</strong> Shares passwords and digital
                  notes securely with 2048-bit RSA encryption.
                </li>
                <li>
                  <strong>Role-Based Access:</strong> Assigns roles to team
                  members for better control and management.
                </li>
                <li>
                  <strong>Dark Web Monitoring:</strong> Built-in scanning alerts
                  you if your credentials appear on the dark web.
                </li>
                <li>
                  <strong>Remote Delete:</strong> Automatically deletes
                  encrypted data from lost or stolen devices.
                </li>
              </ul>
            </p>
          </div>
        </section>
        

      

       

       

       

        <section className="home-section">
          <div className="home-box" data-aos="fade-up">
            <img
              src="https://www.titanhq.com/EE/img/logos/titan-hq-logo.svg"
              alt="logo"
            />
            <h2 className="home-section-title">Titan HQ</h2>
            <p className="home-text">
              <ul>
                <li>
                  <strong>Advanced Phishing Protection:</strong> Utilizes
                  machine learning to detect and remediate sophisticated
                  phishing attacks.
                </li>
                <li>
                  <strong>Microsoft 365 Integration:</strong> Seamlessly
                  integrates with Microsoft 365 to protect both internal and
                  external emails.
                </li>
                <li>
                  <strong>Zero-Day Threat Intelligence:</strong> Provides unique
                  zero-day threat intelligence to combat new and emerging
                  threats.
                </li>
                <li>
                  <strong>Real-Time Remediation:</strong> Offers instant
                  remediation across the entire tenant, including time-of-click
                  protection for malicious links.
                </li>
              </ul>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);

  const servicesList = [
    {
      title: "Managed Services",
      description:
        "IT support tailored to meet the unique needs of businesses. These solutions typically include round-the-clock monitoring, data protection, network security, and seamless integration of various technologies.",
    },
    {
      title: "Managed Server Support",
      description:
        "Proactive monitoring, security management, and performance optimization.",
    },
    {
      title: "Technology Services Audit",
      description:
        "Every business has reoccurring technology bills, we review them and make sure you and your business is paying the best rate for services needed.",
    },
    {
      title: "Internet Security",
      description:
        "Hardware and software firewalls and a layer of software security tools.",
    },
    {
      title: "Phone System Management",
      description: "VOIP phone solutions including teams and SMS integration.",
    },
    {
      title: "Email Security",
      description:
        "Layers of security protection for your emails to protect against malicious attacks.",
    },
    {
      title: "Network Management",
      description:
        "Monitoring, securing and optimizing network performance and dataflow across the local network.",
    },
    {
      title: "Firewall",
      description:
        "Network security device that monitors and controls incoming and outgoing traffic based on predefined security rules. It acts as a barrier between your network and the internet to prevent unauthorized access and threats.",
    },
    {
      title: "Unified Threat Management",
      description:
        "Integrates various security functions like antivirus, firewall, intrusion prevention, and content filtering. This protects against a range of threats, including malware, spam, and phishing attacks.",
    },
    {
      title: "Wi-Fi Management",
      description:
        "Ensures reliable connectivity, minimizes downtime, and enhances the user experience, which is crucial for internet and network connectivity.",
    },
    {
      title: "Software Monitoring and Patching",
      description:
        "Continuously tracking the performance and health of applications to detect and resolve issues proactively. Updating software to fix vulnerabilities, improve functionality, and ensure security.",
    },
    {
      title: "Email Security",
      description:
        "Protecting accounts and communications from unauthorized access, loss, or compromise by using various techniques such as encryption, spam filtering, and malware protection. Used to prevent cyber attacks like phishing, domain spoofing, and account takeovers.",
    },
    {
      title: "Internet Faxing",
      description:
        "Unify your communication by using your email to send and receive faxes like attachments. Keep your fax data easy to manage. Use your existing fax number.",
    },
    {
      title: "File Sharing and Syncing",
      description:
        "Store, access, and collaborate on files from any device, ensuring that the latest versions are always available. These services enhance productivity by enabling real-time updates and seamless collaboration, while also providing robust security and backup features.",
    },
    {
      title: "Data Storage and Backup Solutions",
      description:
        "Provides a secure and reliable method for accessing, storing, and protecting data, that can be recovered in case of loss or damage. This solution utilizes cloud-based services that automatically back up data as well, offering both convenience and peace of mind.",
    },
    {
      title: "Mobile Phone and Tablet Support",
      description:
        "Remote Support manage, monitor, and secure mobile devices like smartphones, tablets, and laptops. Helps enforce security policies, protect sensitive data, and streamline the deployment and maintenance of mobile applications.",
    },
    {
      title: "Web Security",
      description:
        "A range of practices and technologies designed to protect websites, web applications, and users from internet-based threats like malware, phishing, and data breaches. Ensuring the safety of sensitive information and maintains the integrity and availability of online services and capabilities.",
    },
    {
      title: "Home Automation",
      description:
        "Makes life easier, more efficient, and secure by bringing all your smart devices under one roof.",
    },
    {
      title: "Audio-Video Solutions",
      description: (
        <>
          Home Theater Systems: These include high-definition TVs or projectors,
          surround sound speakers, and AV receivers to create a cinematic
          experience at home. Integrate streaming services and voice assistants,
          smart TVs offer a seamless viewing experience.
          <br />
          Multi-Room Audio: Systems like Sonos allow you to play music in
          multiple rooms, controlled via a smartphone app.
          <br />
          Automation: Set predefined programs for lights, music, and anything
          that plugs in to power automated on a preset schedule or set as a
          command.
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="home-title-wp">How We Help</h1>

      <div className="hero-container d-flex">
        <div className="img-conatiner-hero" data-aos="fade-right">
          <img
            className="hero-img"
            src="https://cdn.pixabay.com/photo/2022/03/01/08/11/call-center-7040784_1280.png"
            alt="heroimg"
          />
        </div>
        <div className="hero-txt-container">
          <h1>Technology Services</h1>
          <p>
            Empowering businesses with cutting-edge technology solutions
            tailored to drive innovation and efficiency. From IT support to
            advanced automation and bespoke development, our comprehensive
            services are designed to meet the unique needs of your organization.
            Partner with us to leverage the power of technology and achieve your
            goals with confidence.
          </p>
        </div>
      </div>

      <div className="services-container" data-aos="zoom-in-up">
        {servicesList.map((service, index) => (
          <Tooltip title={service.description} key={index} arrow>
            <div className="service-card">
              <p className="service-title">{service.title}</p>
            </div>
          </Tooltip>
        ))}
      </div>
    </>
  );
};

export const Quicklinks = () => {
  useEffect(() => {
    AOS.init({ duration: 1900 });
  }, []);

  const quicklinksList = [
    {
      name: "NinjaOne Assist",
      url: "https://consultlm.rmmservice.com/connect/#/9201551896",
    },
    {
      name: "iOS NinjaOne Assist",
      url: "https://apps.apple.com/us/app/ninjaone-assist/id6451313359",
    },
    {
      name: "Pro Support",
      url: "https://consultlm.rmmservice.com/installer/1526719042",
    },
    {
      name: "Mac Standard Support",
      url: "https://production.kabutoservices.com/desktop/macos/setup?token=3f6e16b0-a57e-48de-8cf2-e9a933174c59",
    },
    {
      name: "Windows Standard Support",
      url: "https://rmm.syncromsp.com/dl/msi/MjAwMTgwNTUtMTYwODQ3MDk0NC01NDIyMC03NDYyMg==",
    },
    { name: "Password Boss V1", url: "https://www.passwordboss.com/download" },

    {
      name: "Ninja Remote Viewer",
      url: "https://resources.ninjarmm.com/development/ninjacontrol/5.3.3267/ncinstaller-x64.exe",
    },
  ];
  return (
    <div className="quicklinks-container">
      <div>
        {quicklinksList.map((link, index) => (
          <a href={link.url} target="blank" key={index}>
            <button className="quicklink-button">{link.name}</button>
          </a>
        ))}
      </div>
    </div>
  );
};
