// ProjectPhotos.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../pages/css/ProjectPhotos.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

// Set the app element for accessibility
Modal.setAppElement("#root");

const ProjectPhotos = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  const projects = [
    {
      title: "Tech Room",
      imgSrc:
        "/projectPics/70533813740__E05F10B9-F30B-4984-9CD8-B12D548C449B.jpeg",
      gallery: [
        "/projectPics/70533813740__E05F10B9-F30B-4984-9CD8-B12D548C449B.jpeg",
        "/projectPics/IMG_0021.jpeg",
        "/projectPics/IMG_7200.jpeg",
      ],
    },
    {
      title: "Dual 4k Screens",
      imgSrc: "/projectPics/IMG_5051.jpeg",
      gallery: [
        "/projectPics/IMG_5051.jpeg",
        "/projectPics/IMG_0631.jpeg",
        "/projectPics/IMG_0634.jpeg",
      ],
    },

    {
      title: "Network Rack",
      imgSrc: "/projectPics/IMG_0603.jpeg",
      gallery: [
        "/projectPics/IMG_0603.jpeg",
        "/projectPics/IMG_1298.jpeg",
        "/projectPics/IMG_6757.jpeg",
        "/projectPics/IMG_2002.jpeg",
        "/projectPics/IMG_1298.jpeg",
      ],
    },
    {
      title: "Multi Screen/Workstations",
      imgSrc: "/projectPics/IMG_1584.jpeg",
      gallery: [
        "/projectPics/IMG_1584.jpeg",
        "/projectPics/IMG_8263.jpeg",
        "/projectPics/IMG_7753.jpeg",
        "/projectPics/IMG_5789.jpeg",
        "/projectPics/IMG_2909.jpeg",
      ],
    },
    {
      title: "Dual Screen Motorized Desk",
      imgSrc: "/projectPics/IMG_2908.jpeg",
      gallery: [
        "/projectPics/IMG_2908.jpeg",
        "/projectPics/IMG_7223.jpeg",
      ],
    },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (project) => {
    setSelectedProject(project);
    setCurrentImageIndex(0);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProject(null);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProject.gallery.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProject.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Header />

      <div className="conatiner-projects">
        <h1>Projects Gallery</h1>
        <div className="project-photos">
          <div className="photos-flex">
            {projects.map((project, index) => (
              <div
                key={index}
                className="photo-card"
                onClick={() => openModal(project)}
              >
                <div className="img-photo-container">
                  <img src={project.imgSrc} alt={project.title} />
                </div>
                <div className="card-description-photos">
                  <h2>{project.title}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <button class="load-more-btn">Load More</button> */}

        {selectedProject && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Project Gallery"
            className="modal"
            overlayClassName="overlay"
          >
            <div className="modal-header">
              <h2>{selectedProject.title}</h2>
              <button onClick={closeModal} className="close-button">
                &times;
              </button>
            </div>
            <div className="modal-content">
              <button
                onClick={goToPreviousImage}
                className="nav-button previous"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-left"
                >
                  <line x1="19" y1="12" x2="5" y2="12" />
                  <polyline points="12 19 5 12 12 5" />
                </svg>
              </button>
              <img
                src={selectedProject.gallery[currentImageIndex]}
                alt={`${selectedProject.title} ${currentImageIndex + 1}`}
                className="modal-image"
              />
              <button onClick={goToNextImage} className="nav-button next">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </button>
            </div>
          </Modal>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProjectPhotos;
