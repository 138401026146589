import "./App.css";
import MissionStatement from "./pages/aboutus";
import { HomePage } from "./pages/homepage";
import PricingPage from "./pages/PricingPage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectPhotos from "./pages/Projects";
import ClientPortal from "./pages/clientPortal";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route exact path="/pricing" element={<PricingPage />} />
        <Route exact path="/about" element={<MissionStatement />} />
        <Route exact path="/site-projects" element={<ProjectPhotos />} />
        <Route exact path="/client-portal" element={<ClientPortal />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
