import "../pages/css/pricingpage.css";
import HeroPPImg from "../assets/consultation.svg";
import PlusIcom from "../assets/plus-alt-svgrepo-com.svg";
import heroimg from "../assets/hero-img.svg"
import { useState } from "react";

export const PricingPlan = () => {
  return (
    <div className="PPcontainer">
      <h1 className="home-title">Pricing and Plans</h1>
      <div className="PPdecription-container">
        <div className="pp-left-description">
          <div className="pp-left-padding">
            <h2>
              Enjoy discounts based on your license and maintenance plan for
              enhanced value and support.
            </h2>
            <div className="card-pp">
              <div className="pp-card-innertext">
            <p>Onsite / Offsite / Training</p>
            <p>2hr minimum deposit</p>
            <p>After hours x2 rate</p>
            </div>
            <div>
            <img src={heroimg} alt="hero-img"/>

            </div>
          </div>
          </div>
          </div>
         
        <div className="pp-hero-container">
          <img src={HeroPPImg} alt="hero-img" />
        </div>
      </div>
      <h1 className="home-title">Monthly Support</h1>
      <div className="Ms-contaioner">
        <div className="ms-box">
          <p>Licensing</p>
        </div>
        <div className="plus-icon-container">
          <img src={PlusIcom} alt="plus" />
        </div>
        <div className="ms-box2">
          <p>Help</p>
        </div>
        <div className="plus-icon-container">
          <img src={PlusIcom} alt="plus" />
        </div>
        <div className="ms-box3">
          <p>Inital Startup</p>
        </div>
      </div>
      {/* <div className="management-container">
        <h1 className="home-title">Management and Licensing</h1>
        <div className="ml-container">
          <div className="section-container">
            <section className="home-section r-padding">
              <div className="home-box bg-blur">
                <h3>Computer $32 per device</h3>

                <p className="home-text">
                  <ol>
                    <li>Antivirus</li>
                    <li>Backup (Files and Imaging)</li>
                    <li>Security and Support Tool</li>
                    <li>Enhanced System & Software Updates</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>User $42 per person</h3>
                <p className="home-text">
                  <ol>
                    <li>Email and Office Apps</li>
                    <li>Email security (SOC)</li>
                    <li>Email and Onedrive Backup</li>
                    <li>Email Encryption</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>IOT $22 per device</h3>
                <p className="home-text">
                  <ul>
                    <li>
                      Router - Firewall - WIFI - VPN - Switch - DVR - Printers -
                      Mobile - Smart Devices - IOT
                    </li>
                  </ul>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>Remote IT Support $42 per person / device</h3>
                <p className="home-text">
                  <ol>
                    <li>Unlimited Offsite Support (Phone, Text email)</li>
                    <li>Annual onsite visit included (1hr onsite visit)</li>
                  </ol>
                </p>
              </div>
            </section>

            <section className="home-section">
              <div className="home-box bg-blur">
                <h3>Priority Package $500 per site</h3>
                <p className="home-text">
                  <ol>
                    <li>Priority Support (phone, text, email)</li>
                    <li>Unlimited Onsite Support (up to 10hrs Monthly)</li>
                    <li>Loaner Equipment (desktops, laptop)</li>
                  </ol>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div> */}
    </div>
  );
};


export const ServicePlans = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Flat Rate Relationship Based IT Support",
      sections: [
        {
          heading: "Support Features",
          items: [
            "All inclusive remote support",
            "Helpdesk with text, email, and phone support",
            "Technology guidance, resource, and tips",
            "Technology aid and management for 3rd party software, hardware vendor",
            "Antivirus, system maintenance and support tools",
            "Secure remote access",
            "Computer specs and monthly technology report",
            "Technology reference manual",
            "Yearly reoccurring bills audit",
            "Microsoft Office suite",
            "Loaner equipment",
            "Daily offsite data backup",
            "Onedrive / Sharepoint and email backup",
            "Email powered by Microsoft O365",
            "Email security",
          ],
        },
      ],
    },
    {
      title: "Add-Ons for Monthly Plans",
      sections: [
        {
          heading: "Computer Rental",
          items: [
            "Laptop (14 or 15inch) - $60/month",
            "Desktop (includes 27+ inch monitor) - $75/month",
            "New replacement every 2 years",
          ],
        },
        {
          heading: "VOIP Phone",
          items: [
            "Physical Phone - $50/user",
            "Voicemail to email",
            "Find me follow me service ring to cell phone",
            "Text Messaging",
          ],
        },
      ],
    },
    {
      title: "Networking Solutions",
      sections: [
        {
          heading: "WIFI Solutions",
          items: [
            "WIFI $600 + $300 Onboard - two nodes and controller (up to 3000sq ft)",
            "Multiple SSID names and guest mode",
            "Wifi6 with WPA3 Security",
            "Seamless 2.4G and 5G AX/AC/N/G/B Bands",
            "Cloud Management",
          ],
        },
        {
          heading: "Additional Networking",
          items: [
            "Wire Drop",
            "LCD TV and Projector Installation",
            "Firewall Hardware",
            "Network Cabinet",
            "WiFi",
          ],
        },
      ],
    },
    {
      title: "Computers and Laptops",
      sections: [
        {
          heading: "Desktops",
          items: [
            "HP Elitedesk G8 - $900 + $300 setup/onboarding - i5 256SSD 8gb RAM",
            "Apple - $699+ (Special Order)",
            "Mini PC Desktop $600+ R5/i5/16gb/500gb",
          ],
        },
        {
          heading: "Laptops",
          items: [
            "14 inch - $500 + $300 setup/onboarding - i5 256 SSD 8gb RAM",
            "15 inch - $600 + $300 setup/onboarding - i5 256 SSD 8gb RAM",
            "17 inch - $600 + $300 setup/onboarding - i3 256 SSD 8gb RAM",
            "Laptop 14inch / 15inch $600+ R5/i5/8gb/256gb",
            "Apple - $999+ (Special Order)",
          ],
        },
      ],
    },
  ];

  return (
    <div className="tabs">
      <div className="tab-titles">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-title ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].sections.map((section, i) => (
          <div key={i} className="section">
            <h3 className="section-heading">{section.heading}</h3>
            <ul className="section-items">
              {section.items.map((item, j) => (
                <li key={j} className="section-item">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};


export const MSPComparison = () => {
  return (
    <div className="msp-container">
      <div className="home-title">Build Your Stack</div>

      {/* IT Support Comparison */}
      <section className="msp-section">
        <h2>IT Support: Break-Fix vs. Managed Service Provider (MSP)</h2>
        <p>
          Imagine your IT system is like a car. With a <strong>break-fix model</strong>, you only call the mechanic when your car breaks down. It's unpredictable, costly, and nobody enjoys surprise expenses or downtime.
        </p>
        <p>
          In contrast, a <strong>Managed Service Provider (MSP)</strong> acts as your personal pit crew, performing regular maintenance and catching issues before they turn into breakdowns. This proactive approach ensures your business runs smoothly.
        </p>
      </section>

      {/* Benefits of Using an MSP */}
      <section className="msp-benefits">
        <h2>Why Use an MSP? - Benefits of Monthly Support</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/474909/money-transfer.svg" alt="Predictable Costs"/>
            <h3>Predictable Costs</h3>
            <p>Fixed monthly rates without surprise expenses.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/428775/support-online-center.svg" alt="support"/>
            <h3>Proactive Support</h3>
            <p>Identify and resolve issues before they cause downtime.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/43630/business-partnership.svg" alt="partnership"/>
            <h3>Long-Term Partnership</h3>
            <p>Strategic IT guidance for growth.</p>
          </div>
        </div>
      </section>

      {/* Pricing for Monthly Support */}
      <section className="pricing">
        <h2>Pricing for Monthly Support</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/190035/desk-office.svg" alt="remote-support"/>
            <h3>REMOTE SUPPORT</h3>
            <p><strong>Home:</strong> $42 per user</p>
            <p><strong>Business/Pro:</strong> $52 per user</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/84197/devices.svg" alt="devices"/>
            <h3>DEVICES MANAGEMENT</h3>
            <p><strong>Home:</strong> $42 per computer | $12 per tablet/mobile/IoT</p>
            <p><strong>Business/Pro:</strong> $52 per computer | $22 per tablet/mobile/IoT</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/477054/email-download.svg" alt="email-management"/>
            <h3>EMAIL ACCOUNT & MANAGEMENT</h3>
            <p><strong>Home:</strong> $42 per login email address</p>
            <p><strong>Business:</strong> $52 per login email address</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/375468/onboarding.svg" alt="onboarding"/>
            <h3>Onboard / Startup Costs</h3>
            <p><strong>Per new device/person:</strong> $300</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/338038/call-center-support-customer-service-smartphone.svg" alt="onsite visit"/>
            <h3>Onsite Support</h3>
            <p><strong>Unlimited onsite support:</strong> $500 per month</p>
            <p><strong>Hourly Rate:</strong> $150 per hour (minimum 2 hours)</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/514329/repair.svg" alt="break fix"/>
            <h3>Option 2 - Break Fix</h3>
            <p><strong>Standard hourly rate without a maintenance plan:</strong> $250 per hour (2 hrs min onsite)</p>
          </div>
        </div>
      </section>

      {/* Monthly Support Features */}
      <section className="monthly-support">
        <h2>Monthly Support Includes</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/281706/information-desk.svg" alt="helpdesk"/>
            <h3>Helpdesk Support</h3>
            <p>Text, email, and phone support.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/423971/beacon-beam-guidance.svg" alt="guidance"/>
            <h3>Technology Guidance</h3>
            <p>Resources and tips for efficient use.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/492813/moving-female.svg" alt="third party"/>
            <h3>3rd Party Management</h3>
            <p>Aid for software and hardware vendors.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/265320/shield-antivirus.svg" alt="anti virus"/>
            <h3>Antivirus & Maintenance</h3>
            <p>Support tools to keep systems running.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/284845/secure-folder-files-and-folders.svg" alt="secure remote access"/>
            <h3>Secure Remote Access</h3>
            <p>Access to systems from anywhere securely.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/530530/report.svg" alt="monthly report"/>
            <h3>Monthly Technology Reports</h3>
            <p>Insights on system performance.</p>
          </div>
          <div className="card">
            <img src="https://www.svgfind.com/show/10005205.svg" alt="audit"/>
            <h3>Yearly Audit</h3>
            <p>Audit of recurring bills.</p>
          </div>
          <div className="card">
            <img src="https://www.svgfind.com/show/10001517.svg" alt="loaner equipment"/>
            <h3>Loaner Equipment</h3>
            <p>Replacement devices when needed.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/507118/db-copy.svg" alt="backup"/>
            <h3>Backup Solutions</h3>
            <p>Daily offsite and email backup.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/517812/microsoftoffice.svg" alt="msoffice"/>
            <h3>Microsoft O365</h3>
            <p>Email powered by Microsoft O365.</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/387461/email-security.svg" alt="emailsecuirty"/>
            <h3>Email Security</h3>
            <p>Ensuring secure email communication.</p>
          </div>
        </div>
      </section>

      {/* Computer Rental */}
      <section className="computer-rental">
        <h2>Computer Rental</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/396858/laptop.svg" alt="laptop"/>
            <h3>Laptop (14 or 15 inch)</h3>
            <p>$60/month</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/491931/tech-color-desktop.svg" alt="desktop"/>
            <h3>Desktop (27+ inch monitor)</h3>
            <p>$75/month</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/525165/video-frame-replace.svg" alt="replacement"/>
            <h3>Replacement Policy</h3>
            <p>New replacement every 2 years.</p>
          </div>
        </div>
      </section>

      {/* Additional Services */}
      <section className="additional-services">
        <h2>Additional Services</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/528465/pip-2.svg" alt="installation"/>
            <h3>LCD TV & Projector Installation</h3>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/341473/cable-wire-electricity-electronics-power.svg" alt="wiredrop"/>
            <h3>Wire Drop</h3>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/260796/firewall.svg" alt="firewall"/>
            <h3>Firewall Hardware</h3>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/489964/cabinet.svg" alt="cabinet"/>
            <h3>Network Cabinet</h3>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/503667/wifi.svg" alt="wifi setup"/>
            <h3>WIFI Setup</h3>
          </div>
        </div>
      </section>

      {/* Computers & Laptops */}
      <section className="computers-laptops">
        <h2>Computers & Laptops</h2>
        <div className="card-container">
          <div className="card">
            <img src="https://www.svgrepo.com/show/435917/pc.svg" alt="mini pc"/>
            <h3>Mini PC Desktop</h3>
            <p>$600+ (R5/i5/16gb/500gb)</p>
          </div>
          <div className="card">
            <img src="https://www.svgrepo.com/show/395626/pc.svg" alt="laptop"/>
            <h3>Laptop (14inch / 15inch)</h3>
            <p>$600+ (R5/i5/8gb/256gb)</p>
          </div>
        </div>
      </section>
    </div>
  );
};
