import React, { useEffect, useState } from "react";
import MenuIcon from "../assets/menu.svg";
import Logo from "../assets/logo.png";
import useScrollLock from "./useScrollLock";
import "./header.css";
import { Link } from "react-router-dom";
import Tab from "../components/tab";

const Header = () => {
  const [showMenu, setMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  useScrollLock(showMenu);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("noScroll");
    };
  }, []);

  function handleMenu() {
    setMenu(!showMenu);
  }

  const MenuComp = () => (
    <>
      <div className="optionsMenuBox">
        <button onClick={handleMenu} className="close-btn">
          &times;
        </button>
        <div className="pMobile">
          <Link to="/">
            <div className="tab-btn">Services</div>
          </Link>
          <Link to="/about">
            <div className="tab-btn">About</div>
          </Link>
          <Link to="/pricing">
            <div className="tab-btn">Pricing & Plans</div>
          </Link>
          <Link to="/site-projects">
            <div className="tab-btn">Site Projects</div>
          </Link>
          <Link to="/client-portal">
          <div className="tab-btn">Client Portal</div>
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={`headerContainer ${scrolled ? "scrolled" : ""}`}>
        <div className="headerOptions">
          <Link to ="/">
          <div className="logoContainer">
            <img className="logoImg" src={Logo} alt="logo" />
          </div>
          </Link>
          <Tab/>
          <div className="navbar-options">
            <span>
              <a href="tel:8139661802">
                <i className="fas fa-phone"></i> 813 966 1802
              </a>
            </span>
            <span>
              <a href="mailto:support@consultlm.com">
                <i className="fas fa-envelope"></i> support@consultlm.com
              </a>
            </span>
            <span>
              <i className="fas fa-clock"></i> Business Hours Mon - Fri 9a - 4p
            </span>
          </div>
        </div>
      </div>
      <div className="headerMobile">
        <div className="headerMobileOptions">
          <Link to = "/">
          <div className="logoContainer">
            <img className="logoImg" src={Logo} alt="logo" />
          </div>
          </Link>
          <div className="menuIconContainer">
            <img
              className="menuIconImg"
              src={MenuIcon}
              onClick={handleMenu}
              alt="menuIcon"
            />
          </div>
        </div>
        {showMenu ? <MenuComp /> : null}
      </div>
    </>
  );
};

export default Header;
