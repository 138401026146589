import React, { useEffect, useState } from "react";
import "../pages/css/about.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const MissionStatement = () => {
  useEffect(()=>{
    window.scroll(0,0)
  })
  return (
    <>
      <Header />
      <div className="mission-container">
        <section className="mission-statement">
          <h1>Mission Statement</h1>
          <p>
            Provide a high level of technology support to clients that will
            maximize their efficiency and productivity to get work done. Simply
            stated, our mission is “Making Technology Work For You”
            <br />
            <span className="quote-author">- Austin Lerner</span>
          </p>
        </section>

        <section className="company-info">
          <h2>About LM Consulting</h2>
          <p>
            Founded in 2003 by Austin Lerner, LM Consulting has been a trusted
            partner for small businesses in the Tampa area, offering a
            comprehensive approach to IT services. Our diverse clientele
            empowers us to maintain a broad knowledge base and stay ahead of
            current technology trends. We specialize in providing outsourced IT
            services tailored to the needs of individuals and small businesses.
            In today's fast-paced world, reliable IT support is crucial for the
            smooth operation of computers that are integral to daily business
            activities. At LM Consulting, we understand that slow technology can
            hinder productivity. That's why we offer robust helpdesk support,
            monthly preventative maintenance, and secure offsite data backup
            solutions to meet the essential IT needs of our clients. Our use of
            remote access tools ensures quick and efficient tech support,
            minimizing the need for onsite visits and keeping your business
            running smoothly.
          </p>
        </section>

        <section className="team">
          <h2>The Team</h2>
          <div className="team-list">
            {teamMembers.map((member) => (
              <div key={member.name} className="team-member">
                <h3>{member.name}</h3>
                <p>{member.position}</p>
                {member.additionalInfo && <p>{member.additionalInfo}</p>}
              </div>
            ))}
          </div>
        </section>
        <FAQ />
      </div>
      <Footer />
    </>
  );
};

const teamMembers = [
  { name: "Austin Lerner", position: "Owner" },
  { name: "Ben Pettry", position: "Support" },
  { name: "Varun Chauhan", position: "Support" },
  { name: "Soham Chakraborty", position: "Support" },
  { name: "Ishana Qadir", position: "Support" },
  { name: "Sudhir Singh", position: "Support" },
  { name: "Josh Shepherd", position: "Special Projects" },
  { name: "Dave Chong", position: "Special Projects" },
  { name: "Ian Sullivan", position: "Special Projects" },
  { name: "Dillon Brozyna Esq", position: "Special Projects" },
  { name: "Arshiya Rukma", position: "Team Coordinator" },
  { name: "Prakash Argarwal", position: "Billing and Administration" },
];

export default MissionStatement;

export const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqs = [
    {
      question: "What services does LM Consulting provide?",
      answer:
        "We offer all-inclusive tech support for small businesses with fewer than 30 users. Think of us as your tech superheroes, swooping in to save the day!",
    },
    {
      question: "How can I contact LM Consulting for support?",
      answer:
        "You can reach us via phone, email, text, live chat, or a contact form. We promise to respond faster than a speeding bullet—or at least, pretty darn fast.",
    },
    {
      question: "What technologies do you support?",
      answer:
        "Our team is skilled in using tools like Ninjarmm and Bitdefender. If it's tech-related, we've got your back!",
    },
    {
      question: "How do you price your services?",
      answer:
        "Our pricing is relationship-focused, meaning we build long-term partnerships with our clients, offering them comprehensive support without hidden fees.",
    },
    {
      question: "What are your business hours?",
      answer:
        "We operate during standard business hours, but rest assured, our tech support ninjas are flexible and ready to assist when you need it most.",
    },
    {
      question: "How do you maintain productivity?",
      answer:
        "Our secret sauce includes fasting, exercise, a dash of humor, and a well-organized workspace. It keeps us sharp and ready to tackle any tech challenge!",
    },
    {
      question: "Can you tell me about your team?",
      answer:
        "We're a close-knit group of 7 tech enthusiasts who love solving problems and making tech accessible for everyone.",
    },
    {
      question: "How can I stay updated on your latest services and offerings?",
      answer:
        "Follow us on Instagram or check out our website for the latest updates. We promise to keep it engaging and informative!",
    },
    {
      question: "What's your approach to customer acquisition?",
      answer:
        "While we rely on word of mouth, we're also expanding our marketing efforts through B2B outreach, website upgrades, and more.",
    },
    {
      question: "What should I do if my question isn't answered here?",
      answer:
        "Don't worry! Just reach out to us directly. We're here to help and ensure you have the best experience possible.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <p>
        Here, we answer your burning questions about our tech support services.
        Dive in for quick, clear, and occasionally humorous responses!
      </p>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? "active" : ""}`}
        >
          <div className="faq-question" onClick={() => handleToggle(index)}>
            {faq.question}
            <button
              className="faq-toggle-button"
              aria-expanded={activeIndex === index}
              aria-controls={`faq-answer-${index}`}
            >
              {activeIndex === index ? "-" : "+"}
            </button>
          </div>
          {activeIndex === index && (
            <div
              id={`faq-answer-${index}`}
              className="faq-answer"
              role="region"
              aria-labelledby={`faq-question-${index}`}
            >
              {faq.answer}
            </div>
          )}
        </div>
      ))}
      <div className="additional-tips">
        <p>
          <strong>Additional Tips:</strong>
        </p>
        <ul>
          <li>
            For a deeper dive into our services, check out our upcoming
            webinars.
          </li>
          <li>
            Stay tuned for our new website launch for even more exciting
            updates.
          </li>
        </ul>
        <p>
          We're always here to help, so feel free to contact us if you have any
          more questions. Your satisfaction is our top priority!
        </p>
      </div>
    </div>
  );
};
